import React, { createContext, useState, useContext, useEffect, useRef, useMemo } from 'react';

const Context = createContext(null);

export const useData = () => useContext(Context);

export const ContextProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const [posts, setPosts] = useState(new Map());
  const [room, setRoom] = useState("");
  const [usersCount, setUsersCount] = useState(0);
  const [alertSoundOn, setAlertSoundOn] = useState(false);
  const [chatList, setChatList] = useState([]);

  const roomRef = useRef(room);
  const alertSoundOnRef = useRef(alertSoundOn);

  const [postSearchInput, setPostSearchInput] = useState("");
  const [postSearchResult, setPostSearchResult] = useState([]);
  
  const audio = useMemo(() => new Audio("alert.mp3"), ["alert.mp3"]);

  useEffect(() => {
    roomRef.current = room;
    alertSoundOnRef.current = alertSoundOn;
  }, [room, alertSoundOn]);
  
  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      }
    }

    audio.load();
    const socket_ = new WebSocket("wss://mm1lnrsuk0.execute-api.ap-northeast-2.amazonaws.com/production")
    
    const onConnect = (e) => {
      sendCount();
    }

    const onDisconnect = (e) => {
    }

    const onError = (e) => {
      console.log('error', e)
    }

    const onMessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.type === 'post') {
        setPosts(currentPosts => {
          const newPosts = new Map(currentPosts);
          newPosts.set(data.data.id, data.data);
          return newPosts;
        });

      } else if (data.type === 'my_post') {
        setRoom(data.data.id);
        setChatList([]);
      
      } else if (data.type === 'delete') {
        setPosts(currentPosts => {
          const newPosts = new Map(currentPosts);
          newPosts.delete(data.id);
          return newPosts;
        });

      } else if (data.type === 'delete_alert') {
        setRoom("");
        setChatList([]);
        alert(data.message);
      
      } else if (data.type === 'join_fail') {
        alert(data.message);
      
      } else if (data.type === 'join') {
        setPosts(currentPosts => {
          const newPosts = new Map(currentPosts);
          if (newPosts.has(data.id)) {
            if (!newPosts.get(data.id)['members']) {
              newPosts.get(data.id)['members'] = [];
            }
            if (!newPosts.get(data.id)['members'].includes(data.member)) {
              newPosts.get(data.id)['members'].push(data.member);
            }
          }
          return newPosts;
        });

      } else if (data.type === 'leave') {
        setPosts(currentPosts => {
          const newPosts = new Map(currentPosts);
          if (newPosts.has(data.id)) {
            if (!newPosts.get(data.id)['members']) {
              newPosts.get(data.id)['members'] = [];
            }
            newPosts.get(data.id)['members'] = newPosts.get(data.id)['members'].filter(id => id !== data.member);
          }
          return newPosts;
        });

      } else if (data.type === 'chat') {
        if (roomRef.current === data.post) {
          if (data.sender !== "server" && data.sender !== data.me) {
            sendNoti("새로운 채팅이 있습니다.")
            if (alertSoundOnRef.current) {
              audio.currentTime = 0.25;
              audio.play();
            }
          }
          setChatList(currentChatList => [...currentChatList, data]);
        }

      } else if (data.type === 'count') {
        setUsersCount(data.count)

      } else if (data.type === 'pong') {
        // console.log("pong");
      }
    }

    socket_.addEventListener('open', onConnect);
    socket_.addEventListener('close', onDisconnect);
    socket_.addEventListener('error', onError);
    socket_.addEventListener('message', onMessage);

    setSocket(socket_);

    const pingpong = setInterval(() => {
      const data = {
        action: 'ping',
        data: {}
      };
      if (socket_.readyState === WebSocket.OPEN) {
        socket_.send(JSON.stringify(data));
        // console.log("ping");
      } else {
        console.log('WebSocket is not open');
      }
    }, 180000);

    const sendCount = () => {
      const data = {
        action: 'count',
        data: {}
      };
      if (socket_.readyState === WebSocket.OPEN) {
        socket_.send(JSON.stringify(data));
      } else {
        console.log('WebSocket is not open');
      }
    }
    
    setTimeout(() => {
      const uri = "https://daa14cievc.execute-api.ap-northeast-2.amazonaws.com/production/getPosts";
      
      fetch(uri, {
        method : "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then(res => res.json())
      .then(res => {
        const resultMap = new Map();
        for (const post of res) {
          resultMap.set(post.id, post);
        }
        setPosts(resultMap);
      }); 
    }, 1000)
    
    return () => {
      clearInterval(pingpong);
      
      if (socket_) {
        socket_.removeEventListener('open', onConnect);
        socket_.removeEventListener('close', onDisconnect);
        socket_.removeEventListener('error', onError);
        socket_.removeEventListener('message', onMessage);
        socket_.close();
      }
    };
  }, [])

  const sendNoti = (notiText) => {
    if (Notification.permission === "granted") {
      const noti = new Notification("템지지 - TEM.GG", {
        icon: "favicon.png",
        body: notiText
      })
    }
  }

  return (
    <Context.Provider value={{ 
      socket, 
      posts, 
      room, 
      setRoom, 
      chatList, 
      setChatList,
      postSearchInput,
      setPostSearchInput,
      postSearchResult,
      setPostSearchResult,
      alertSoundOn,
      setAlertSoundOn,
      usersCount
      }}>
      {children}
    </Context.Provider>
  );
};
