import Nav from "./Nav"
import Body from "./Body"
import { useEffect } from "react";

function Main() {

  return (
    <div className="relative">
      <Nav />
      <Body />
    </div>
  );
}

export default Main;