import { useState } from 'react';
import { useData } from '../context/Context';
import itemData from './item_data.json';
import Job from './Job';

function PostModal() {
  const { socket } = useData();
  const [ worldNick, setWorldNick ] = useState("");
  const [ inGameNick, setInGameNick ] = useState("");
  const [ max, setMax ] = useState(6);
  const [ content, setContent ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);
  const [ page, setPage ] = useState(0);
  const [ searchResult, setSearchResult ] = useState([]);
  const [ searchInput, setSearchInput ] = useState("");
  const [ items, setItems ] = useState([]);
  const [ showSearchResult, setShowSearchResult ] = useState(false);
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  const enterButtonHandler = () => {
    if (max > 6 || max < 2) {
      alert("잘못된 입력입니다.");
      return;
    }
    if (inGameNick.includes(" ") || worldNick.includes(" ")) {
      alert("닉네임에 공백이 있습니다.");
      return;
    }
    if (inGameNick === "" || worldNick === "") {
      alert("닉네임을 입력해주세요.");
      return;
    }
    if (content.replaceAll(" ", "") === "") {
      alert("글을 작성해주세요.");
      return;
    }
    for (const item of items) {
      if ("options" in item) {
        if (+item.options["업그레이드 횟수"] + +item.options["업그레이드 가능 횟수"] > itemData[item.id].options["업그레이드 가능 횟수"]) {
          alert(`${itemData[item.id].name}의 '업그레이드 횟수'와 '업그레이드 가능 횟수'가 잘못 입력됐습니다.`);
          return;
        }
        for (const [optionName, option] of Object.entries(item.options)) {
          if (optionName !== "공격속도" && +option < 0) {
            alert(`${itemData[item.id].name}의 '${optionName}' 값에 문제가 있습니다.`);
            return;
          }
        }
      }
    }

    if (socket) {
      const data = {
        action: 'post',
        data: {
          content: content,
          max: `${max}`,
          world: worldNick,
          ingame: inGameNick,
          items: items
        }
      };
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(data));
        document.querySelector(".close-button").click();
        setTimeout(() => {
          setPage(0);
        }, 500);
      } else {
        console.log('WebSocket is not open');
      }
    }
  }

  const inputChangeHandler = (e) => {
    setSearchResult([]);
    setSearchInput(e.target.value);
    setShowSearchResult(true);
    const inputValue = e.target.value.replaceAll(" ", "");
    if (!inputValue) return;
    let tempResult = []
    for (const [itemId, item] of Object.entries(itemData)) {
      let count = 0;
      let begin = undefined;
      const indexList = [];
      for (let i = 0; i < inputValue.length; i++) {
        if (inputValue[i] === " ") continue;
        const index = item.name.indexOf(inputValue[i], begin);
        if (index !== -1) {
          begin = index;
          indexList.push(index);
          count++;
        } else {
          break;
        }
      }
      if (count == inputValue.length) {
        let sum = indexList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        tempResult.push([sum, itemId]);
        tempResult.sort((a, b) => a[0] - b[0]);
        setSearchResult(tempResult.map(innerArray => innerArray[1]));
      }
    }
  }

  const searchResultComponent = (searchResult) => {
    return searchResult.map((itemId, index) => {
      return (
        <button key={index} onClick={() => {
          setItems(prevItems => {
            if (prevItems.length >= 5) {
              alert("아이템을 더 게시할 수 없습니다.");
              return prevItems;
            }
            let tempObject = {
              id: itemId
            }
            if ('options' in itemData[itemId]) {
              tempObject["options"] = {}
              tempObject["options"]["업그레이드 횟수"] = 0;
              for (const [optionName, option] of Object.entries(itemData[itemId].options)) {
                if (optionName === "업그레이드 가능 횟수") {
                  tempObject["options"][optionName] = option;
                } else if (optionName === "공격속도") {
                  tempObject["options"][optionName] = option;
                } else {
                  tempObject["options"][optionName] = option.mid;
                }
              }
            }
            if ('desc' in itemData[itemId]) {
              tempObject["desc"] = itemData[itemId].desc;
            }
            const newItems = [tempObject, ...prevItems];
            
            return newItems;
          })
          setTimeout(() => {
            setSelectedIndex(0)
            if (items.length) {
              document.querySelector("#itemCardButton0").click();
            }
          }, 100);

        }} className='btn btn-sm btn-ghost w-full flex flex-row justify-between mb-1'>
          <img className='object-contain' width={16} src={itemData[itemId].image} alt={itemData[itemId].name} />
          <span className='text-sm'>{itemData[itemId].name}</span>
        </button>
      );
    })
  }

  const itemCardButtonComponent = (items) => {
    return items.map((item, index) => {
      let className = "btn btn-sm btn-circle";
      if (index === selectedIndex) {
        className = "btn btn-sm btn-circle btn-primary";
      }
      return (
        <a key={index + "a"} href={"#item" + index} className={className} id={"itemCardButton" + index} onClick={() => { setSelectedIndex(index); }}>
          <img className='object-contain w-5 h-5' src={itemData[item.id].image} alt={itemData[item.id].name + "_button"} />
        </a>
      )
    }
    )
  }

  const itemCardsComponent = (items) => {
    return items.map((item, itemIndex) => {
      return (
        <div key={itemIndex + "items"} id={"item" + itemIndex} className='carousel-item flex flex-col items-center h-fit w-11/12 bg-[#5E5C90] text-slate-200 p-2 rounded-box mt-2 relative'>
          <div>
            <span className='text-xl font-bold mr-1'>{itemData[item.id].name}</span>
            {
              'options' in item ?
              <span className='text-xl font-bold mr-1'>(+
              <input type='number' value={item.options["업그레이드 횟수"]} style={{
                background: "#7F7FA9",
                width: "24px",
                textAlign: "center",
                borderRadius: "5px",
                max: itemData[item.id].options["업그레이드 가능 횟수"],
                min: "0"
              }} 
              onChange={(e) => {
                if (+e.target.value < 0) return;
                setItems(prevItems => {
                  const newItems = [...prevItems];
                  newItems[itemIndex].options["업그레이드 횟수"] = +e.target.value;
                  return newItems;
                })
              }}
              />
              )</span> : ""
            }
          </div>
          <div className='flex flex-row w-full mt-2'>
            {
              item.id[0] === "1" ?
              <div className='bg-slate-200 w-24 h-24 flex justify-center items-center mr-3 ml-3 mt-1 mb-1'>
                <img className='object-contain w-20 h-20' src={itemData[item.id].image} />
              </div>
              :
              <div className='bg-slate-200 w-16 h-16 flex justify-center items-center mr-3 ml-3 mt-1 mb-1'>
                <img className='object-contain w-12 h-12' src={itemData[item.id].image} />
              </div>
            }
            {
              item.id[0] === "1" ?
              <div className='text-xs ml-1 pl-1'>
                <div>
                  <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ LEV :</span>
                  <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_lev}</span>
                </div>
                <div>
                  <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ STR :</span>
                  <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_str}</span>
                </div>
                <div>
                  <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ DEX :</span>
                  <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_dex}</span>
                </div>
                <div>
                  <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ INT :</span>
                  <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_int}</span>
                </div>
                <div>
                  <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ LUK :</span>
                  <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_luk}</span>
                </div>
                <div>
                  <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ POP :</span>
                  <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_pop}</span>
                </div>
              </div>
              :
              <div className='text-xs ml-1 pl-1 w-36'>
                {"desc" in itemData[item.id] ? itemData[item.id].desc : ""}
              </div>
            }
          </div>
          {
            "job" in itemData[item.id] ?
            <Job job={itemData[item.id].job} /> : ""
          }
          {
            item.id[0] === "1" ?
            <hr className='border-1 w-full mt-2 mb-2' /> : ""
          }
          {
            item.id[0] === "1" ?
            <div className='flex flex-col items-center'>
              <div className='mb-1'>
                <span>장비분류 :</span>
                <span className='ml-1'>{itemData[item.id].category[2]}</span>
              </div>
              {
                "options" in itemData[item.id] && "공격속도" in itemData[item.id].options ?
                <div className='mb-1'>
                  <span>공격속도 :</span>
                  <span className='ml-1'>{itemData[item.id].options["공격속도"]}</span>
                </div>
                : ""
              }
              {
                "options" in itemData[item.id] ?
                Object.entries(item.options).map((option, optionIndex) => {
                  if (option[0] === "업그레이드 횟수" 
                  || option[0] === "업그레이드 가능 횟수"
                  || option[0] === "공격속도") {
                    return
                  }
                  return (
                  <div className="dropdown dropdown-right dropdown-hover flex flex-row items-center">
                    <div key={optionIndex + "opt"} tabIndex={0} className='mb-1 relative'>
                      <span>{option[0]} :</span>
                      <span className='ml-1'>
                        +
                        <input type='number' value={option[1]} style={{
                          background: "#7F7FA9",
                          width: "28px",
                          textAlign: "center",
                          borderRadius: "5px",
                          min: "0"
                        }} onChange={(e) => {
                          if (+e.target.value < 0) return;
                          setItems(prevItems => {
                            const newItems = [...prevItems];
                            newItems[itemIndex].options[option[0]] = +e.target.value;
                            return newItems;
                          })
                        }} />
                      </span>
                    </div>
                    <div tabIndex={0} className="dropdown-content pl-2">
                      <button onClick={() => {
                        setItems(prevItems => {
                          const newItems = [...prevItems];
                          if (!(option[0] in newItems[itemIndex].options)) {
                            return prevItems;
                          }
                          delete newItems[itemIndex].options[option[0]];
                          return newItems;
                        })
                      }} className="btn btn-circle btn-error btn-xs text-white">
                        ⎯
                      </button>
                    </div>
                  </div>
                  )
                }) : ""
              }
              {
                "options" in itemData[item.id] ?
                <div className='mb-1'>
                  <span>업그레이드 가능 횟수 :</span>
                  <span className='ml-1'>
                    <input type='number' value={item.options["업그레이드 가능 횟수"]} style={{
                      background: "#7F7FA9",
                      width: "28px",
                      textAlign: "center",
                      borderRadius: "5px",
                      max: itemData[item.id].options["업그레이드 가능 횟수"],
                      min: "0"
                    }} onChange={(e) => {
                      if (+e.target.value < 0 || +e.target.value > itemData[item.id].options["업그레이드 가능 횟수"]) return;
                      setItems(prevItems => {
                        const newItems = [...prevItems];
                        newItems[itemIndex].options["업그레이드 가능 횟수"] = e.target.value;
                        return newItems;
                      })
                    }}
                     />
                  </span>
                </div> : ""
              }
              <div>
              <select onChange={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems];
                  if (e.target.value === "" || e.target.value in newItems[itemIndex].options) {
                    return prevItems;
                  }
                  newItems[itemIndex].options[e.target.value] = 0;
                  return newItems;
                })
              }} className="select select-bordered select-xs w-full max-w-xs bg-[#7F7FA9]" value="">
                <option value="">추가 옵션</option>
                <option value="공격력">공격력</option>
                <option value="마력">마력</option>
                <option value="STR">STR</option>
                <option value="DEX">DEX</option>
                <option value="INT">INT</option>
                <option value="LUK">LUK</option>
                <option value="마법방어력">마법방어력</option>
                <option value="물리방어력">물리방어력</option>
                <option value="명중률">명중률</option>
                <option value="회피율">회피율</option>
                <option value="HP">HP</option>
                <option value="MP">MP</option>
                <option value="이동속도">이동속도</option>
                <option value="점프력">점프력</option>
              </select>
              </div>
            </div> : ""
          }
          <button onClick={() => {
            setItems(prevItems => {
              const newItems = [...prevItems];
              newItems.splice(itemIndex, 1);
              return newItems;
            });
          }} className="btn btn-circle btn-xs btn-error text-white" style={{ position: "absolute", top: "-5px", right: "-5px" }}>
            ⎯
          </button>
        </div>
      )
    })
  }

  return (
    <dialog id="my_modal_3" className="modal">
      <div className="modal-box max-w-sm">
        <form method="dialog">
          <button onClick={() => {
            setTimeout(() => {
              setPage(0);
            }, 500);
          }} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 close-button">✕</button>
        </form>
        <div className='flex flex-row'>
          {
            page !== 0
            ?
            <button onClick={() => { setPage(0); }} className='mr-2'>
              <svg xmlns="http://www.w3.org/2000/svg" width="12pt" height="12pt" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
            </button>
            :
            ""
          }
          <h3 className="font-bold text-lg">글 등록하기</h3>
        </div>
        {
          page === 0
          ?
          <div className='flex flex-row mt-4 flex-wrap w-full justify-center'>
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">월드닉:</span>
              </div>
              <input onChange={(e) => {
                setWorldNick(e.target.value);
              }} maxLength={16} value={worldNick} type="text" placeholder="" className="input input-sm input-bordered w-full max-w-xs" />
            </label>
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">인겜닉:</span>
              </div>
              <input onChange={(e) => {
                setInGameNick(e.target.value);
              }} maxLength={6} value={inGameNick} type="text" placeholder="" className="input input-sm input-bordered w-full max-w-xs" />
            </label>
            {/* <div className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">태그:</span>
              </div>
              <div className='flex flex-row'>
                <label className="cursor-pointer label mr-2">
                  <span className="label-text mr-1">파티</span>
                  <input type="checkbox" defaultChecked className="checkbox checkbox-warning  checkbox-xs" />
                </label>
                <label className="cursor-pointer label mr-2">
                  <span className="label-text mr-1">삽니다</span>
                  <input type="checkbox" defaultChecked className="checkbox checkbox-error  checkbox-xs" />
                </label>
                <label className="cursor-pointer label mr-2">
                  <span className="label-text mr-1">팝니다</span>
                  <input type="checkbox" defaultChecked className="checkbox checkbox-info  checkbox-xs" />
                </label>
              </div>
            </div> */}
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">최대 인원: {max}</span>
              </div>
              <input onChange={(e) => {
                setMax(e.target.value);
              }} value={max} type="range" min="2" max="6" className="range range-xs" />
            </label>
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">내용:</span>
              </div>
              <textarea onChange={(e) => {
                let value = e.target.value;
                const maxLines = 10; // 최대 허용 라인 수
                let lines = value.split('\n'); // 입력된 텍스트를 줄바꿈 기준으로 나눔
              
                if (lines.length > maxLines) {
                  // 최대 라인 수를 초과한 경우, 초과분 제거
                  lines = lines.slice(0, maxLines);
                  value = lines.join('\n');
                }
                setContent(value);
              }} placeholder="[팝니다] 공10노목 ㅍㅍㅍㅍ" wrap='hard' value={content} className="textarea textarea-bordered w-full max-w-xs"></textarea>
            </label>
            <button onClick={() => {
              setPage(1);
            }} className='btn mt-5 btn-accent' disabled={isLoading}>다음</button>
            
          </div>
          :
          <div className='flex flex-row mt-4 flex-wrap w-full justify-center'>
            <label className="relative form-control w-full max-w-xs">
              <div className="label flex flex-col justify-start items-start">
                <span className="label-text">아이템 (최대 5개까지 등록 가능합니다.)</span>
                <span className='text-xs text-gray-500'>* 아이템 등록은 선택사항입니다.</span>
              </div>
              <div className="">
                <input onInput={(e) => {
                  inputChangeHandler(e);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setShowSearchResult(false);
                  }, 100);
                }}
                value={searchInput} type="text" placeholder="게시할 아이템을 검색하세요." role="button" className="input input-sm input-bordered w-full max-w-xs" />
                {
                  showSearchResult ?
                  <ul className="z-[1] p-2 shadow bg-base-100 rounded-box w-full max-h-52 overflow-scroll absolute">
                  {
                    searchResult.length ?
                    searchResultComponent(searchResult) :
                    <li>
                      <span className='text-xs pl-1'>검색결과가 없습니다.</span>
                    </li>
                  }
                  </ul>
                  :
                  ""
                }
              </div>
              <div className="flex justify-center w-full mt-4 gap-2 py-2">
                {itemCardButtonComponent(items)}
              </div>
              <div className="carousel carousel-center min-h-72 max-w-md px-4 space-x-4 rounded-box">
                {itemCardsComponent(items)}
              </div>
            </label>
            <button onClick={enterButtonHandler} className='btn mt-5 btn-accent' disabled={isLoading}>글 등록하기</button>
            {/* <form method="dialog">
            </form> */}
          </div>
        }
      </div>
    </dialog>
  );
}

export default PostModal;