import { useData } from '../context/Context';
import itemData from './item_data.json';
import ItemInfo from './ItemInfo';

function Post({ post, index }) {
  const { setRoom, socket, room } = useData();
  const cn = `card w-96 bg-white drop-shadow-xl p-4 border border-slate-100 h-fit m-3 p-6 min-h-64 flex hover:z-20`
  return (
    <div key={index} className={cn}>
      <div className="flex flex-row justify-between">
        <div>
          {
            post.items.map((item, itemIndex) => 
            <div key={itemIndex + "item_image"} className="dropdown dropdown-hover">
              <div tabIndex={0} role="button" className="btn btn-sm btn-circle">
                <img className='object-contain w-5 h-5' src={itemData[item.id].image} alt={itemData[item.id].name + "_item_image"} />
              </div>
              <ItemInfo item={item} itemIndex={itemIndex} />
            </div>
            )
          }
        </div>
        
        <div className="flex flex-row items-center justify-end mr-1 mb-1">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="10pt" height="10pt" viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M6220 11264 c-306 -38 -562 -121 -793 -257 -615 -363 -980 -1025 -954 -1732 15 -400 141 -764 377 -1085 80 -109 311 -338 417 -413 244 -173 510 -285 807 -339 173 -32 479 -32 652 0 297 54 563 166 807 339 106 75 337 304 417 413 179 244 297 518 352 820 18 94 22 154 22 330 0 244 -16 356 -78 562 -186 611 -672 1098 -1283 1284 -183 55 -320 76 -528 79 -99 2 -196 1 -215 -1z"/>
            <path d="M4775 6814 c-113 -15 -243 -40 -325 -64 -712 -207 -1218 -811 -1299 -1552 -7 -65 -11 -689 -11 -1883 l0 -1785 3260 0 3260 0 0 1785 c0 1185 -4 1818 -11 1883 -80 740 -600 1357 -1309 1555 -232 65 -102 61 -1900 63 -899 0 -1648 0 -1665 -2z"/>
            </g>
          </svg>
          <span className="text-xs ml-1">
            {post.members.length} / {post.max}
          </span>
        </div>
      </div>
      <div className="card grow p-1">
        <pre><p className="break-all whitespace-break-spaces">{post.content}</p></pre>
      </div>
      <div className='divider'></div>
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <span className="text-xs text-slate-500">월드 닉네임 : {post.world}</span>
            <span className="text-xs text-slate-500 mt-1">인게임 닉네임 : {post.ingame}</span>
          </div>
        </div>
        <button onClick={() => {
          if (room === post.id) {
            alert("이미 채팅방에 들어와있습니다.");
            return;
          }
          if (room) {
            alert("새로운 채팅방에 들어가려면 기존 채팅방을 나가야합니다.");
            return;
          }
          
          if (socket) {
            const data = {
              action: 'join',
              data: {
                id: post.id
              }
            };
            if (socket.readyState === WebSocket.OPEN) {
              if (post.members.length < post.max) {
                socket.send(JSON.stringify(data));
                setRoom(post.id);
              } else {
                alert("채팅방이 가득찼습니다.");
              }
            } else {
              console.log('WebSocket is not open');
            }
          }
        }} className="btn btn-sm btn-accent">채팅하기</button>
      </div>
    </div>
  );
}

export default Post;