import Chat from "./Chat";
import { useData } from '../context/Context';
import Post from "./Post";
import { useEffect } from "react";
import PostSearch from './PostSearch';

function Body() {
  const { posts, room, postSearchResult, postSearchInput, usersCount } = useData();

  const searchPostsComponent = (postSearchResult) => {
    if (!postSearchResult.length) return <></>;
    return postSearchResult.map((postId, index) => <Post key={index} post={posts.get(postId)} index={index} />);
  }

  const postsComponent = (posts) => {
    if (!posts.size) return <></>;
    const sortedPosts = [...Array.from(posts)].sort((a, b) => b[1].timestamp - a[1].timestamp);
    return sortedPosts.map(([postId, post], index) => {
      if (postSearchResult.includes(postId)) return;
      
      return <Post key={index} post={post} index={index} />
    })
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '70px',
      padding: '20px'
    }}>
      
      <PostSearch />
      <div className="flex flex-col justify-center items-center w-full mt-6 max-w-[1300px]">
        {
          postSearchInput.replace(" ", "")
          ?
          <div className="text-lg font-bold">"{postSearchInput}"의 검색 결과 ({postSearchResult.length}건)</div> 
          :
          ""
        }
        {
          postSearchInput.replace(" ", "") ?
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'start',
            alignItems: 'start'
          }}>
            {
              postSearchResult.length
              ?
              searchPostsComponent(postSearchResult)
              :
              <span className="p-4 text-sm text-gray-500">검색 결과가 없습니다.</span>
            }
          </div>
          : ""
        }
        {
          postSearchInput.replace(" ", "")
          ?
          <div className="divider"></div> 
          :
          ""
        }
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          position: 'relative'
        }}>
          {postsComponent(posts)}
          <div className="card w-96 bg-white drop-shadow-xl p-4 border border-slate-100 h-fit m-3 p-6 min-h-64 flex hover:z-20">
            <div>
              <h1 className="font-bold p-1">공지사항</h1>
              <p className='text-sm p-1'>
                템지지는 메이플랜드에서 아이템 거래나 파티모집을 편하게 할 수 있도록 만들어졌습니다. 자유시장 채팅이나 고확처럼 사용하시면 됩니다.
              </p>
              <p className='text-sm p-1'>
                우측상단의 버튼으로 글을 작성하시면 채팅방이 만들어집니다. 새로고침을 하거나 브라우저 창을 닫으면 글이 지워지고 채팅방이 사라집니다.
              </p>
              <p className='text-sm p-1'>
                비방, 욕설, 현금거래 및 불법 행위에 관련된 글을 올리실 경우 경고없이 이용제한이 생길 수 있으며 경우에 따라 법적 책임을 물을 수 있습니다.
              </p>
              <a className='text-sm p-1 link link-info' href="https://open.kakao.com/o/sIYnZYgg" target="_blank" rel="noreferrer">버그제보 & 문의하기</a>
            </div>
          </div>
          <div className="card w-96 bg-white drop-shadow-xl p-4 border border-slate-100 h-fit m-3 p-6 min-h-64 flex hover:z-20">
            <div>
              <h1 className="font-bold p-1">유용한 사이트 바로가기</h1>
              <div className="flex flex-col p-1 text-sm">
                <a className='link link-hover' href="https://mapledb.kr/" target="_blank" rel="noreferrer">메이플랜드 데이터베이스</a>
                <a className='link link-hover' href="https://www.mlauction.net/" target="_blank" rel="noreferrer">메이플랜드 옥션 (아이템 시세확인)</a>
                <a className='link link-hover' href="https://mapletimer.com" target="_blank" rel="noreferrer">메이플랜드 사냥 타이머</a>
                <a className='link link-hover' href="https://mapleland.me/" target="_blank" rel="noreferrer">메이플랜드 N방컷 확률 계산기</a>
                <a className='link link-hover' href="https://rhghkr.net/" target="_blank" rel="noreferrer">고확 다시보기</a>
                <a className='link link-hover' href="https://xn--o80b01o9mlw3kdzc.com/" target="_blank" rel="noreferrer">메이플노트</a>
                <a className='link link-hover' href="https://www.mapleland.gg/" target="_blank" rel="noreferrer">메랜지지</a>
                <a className='link link-hover' href="https://pati.gg/" target="_blank" rel="noreferrer">파티지지</a>
                <a className='link link-hover' href="https://djaj.gg/" target="_blank" rel="noreferrer">어머지지</a>
                <a className='link link-hover' href="https://osmlib.com/?locale=ko" target="_blank" rel="noreferrer">osmlib</a>
              </div>
            </div>
          </div>
        </div>
        {
          room
          ?
          <Chat />
          :
          ""
        }
      </div>
    </div>
  );
}

export default Body;
