import { useEffect, useState } from 'react';
import { useData } from '../context/Context';
import PostModal from './PostModal';
import PostSearch from './PostSearch';

function Nav() {
  const { socket, room, posts, setRoom, setChatList, usersCount } = useData();
  const [ isLoading, setIsLoading ] = useState(false);

  return (
    <div className="navbar bg-base-200 fixed top-0 z-50">
      <div className="navbar-start">
        <div className="dropdown dropdown-hover">
          <div tabIndex={0} role="button" style={{
            fontFamily: "PartialSansKR-Regular"
          }} className="btn btn-sm m-1 text-gray-700 font-light">메이플랜드</div>
          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
            <li><a href='https://lonaworld.tem.gg' rel="noreferrer" style={{
            fontFamily: "PartialSansKR-Regular"
          }} className="text-gray-700 font-light">로나월드</a></li>
          </ul>
        </div>
      </div>
      <div className="navbar-center flex flex-col">
        <div className='flex flex-row'>
          <h1 style={{
            fontFamily: "PartialSansKR-Regular"
          }} className="text-xl text-gray-700">TEM.GG</h1>
          <h1 style={{
            fontFamily: "PartialSansKR-Regular"
          }} className="text-xl ml-2 text-gray-700">자유시장</h1>
        </div>
        <div className="flex flex-row pt-1">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="10pt" height="10pt" viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M6220 11264 c-306 -38 -562 -121 -793 -257 -615 -363 -980 -1025 -954 -1732 15 -400 141 -764 377 -1085 80 -109 311 -338 417 -413 244 -173 510 -285 807 -339 173 -32 479 -32 652 0 297 54 563 166 807 339 106 75 337 304 417 413 179 244 297 518 352 820 18 94 22 154 22 330 0 244 -16 356 -78 562 -186 611 -672 1098 -1283 1284 -183 55 -320 76 -528 79 -99 2 -196 1 -215 -1z"/>
            <path d="M4775 6814 c-113 -15 -243 -40 -325 -64 -712 -207 -1218 -811 -1299 -1552 -7 -65 -11 -689 -11 -1883 l0 -1785 3260 0 3260 0 0 1785 c0 1185 -4 1818 -11 1883 -80 740 -600 1357 -1309 1555 -232 65 -102 61 -1900 63 -899 0 -1648 0 -1665 -2z"/>
            </g>
          </svg>
          <span className='text-xs'>{usersCount}명 접속 중</span>
        </div>
      </div>
      <div className="navbar-end">
        {/* You can open the modal using document.getElementById('ID').showModal() method */}
        <button className="btn btn-circle btn-ghost" onClick={() => {
          if (room) {
            let result = window.confirm("새 글을 등록하려면 채팅방을 나가야합니다. 채팅방에서 나가시겠습니까?");
            if (result) {
              setIsLoading(true);
              if (socket) {
                const data = {
                  action: 'leave',
                  data: {
                    id: posts.get(room).id
                  }
                };
                if (socket.readyState === WebSocket.OPEN) {
                  socket.send(JSON.stringify(data));
                  setRoom("");
                  setChatList([]);
                } else {
                  console.log('WebSocket is not open');
                }
              }
            } else {
              return;
            }
          }
          document.getElementById('my_modal_3').showModal()
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15pt" height="15pt" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/></svg>
        </button>
        <PostModal />
      </div>
    </div>
  );
}

export default Nav;