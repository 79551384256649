import Main from "./Components/Main"
import { ContextProvider } from './context/Context';

function App() {
  return (
    <ContextProvider>
      <div className="App">
        <Main />
      </div>
    </ContextProvider>
  );
}

export default App;
