const Job = ({ job }) => {
  let newbie = "text-rose-500";
  let worrior = "text-rose-500";
  let wizard = "text-rose-500";
  let archer = "text-rose-500";
  let thief = "text-rose-500";

  if (job.includes("공용")) {
    newbie = "text-slate-200";
    worrior = "text-slate-200";
    wizard = "text-slate-200";
    archer = "text-slate-200";
    thief = "text-slate-200";
  }
  if (job.includes("초보자")) {
    newbie = "text-slate-200";
  }
  if (job.includes("전사")) {
    worrior = "text-slate-200";
  }
  if (job.includes("마법사")) {
    wizard = "text-slate-200";
  }
  if (job.includes("궁수")) {
    archer = "text-slate-200";
  }
  if (job.includes("도적")) {
    thief = "text-slate-200";
  }
  
  return (
    <div className='flex flex-row justify-evenly w-full'>
      <span className={newbie}>초보자</span>
      <span className={worrior}>전사</span>
      <span className={wizard}>마법사</span>
      <span className={archer}>궁수</span>
      <span className={thief}>도적</span>
    </div>
  )
}

export default Job;