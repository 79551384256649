import { useData } from '../context/Context';
import itemData from './item_data.json';

function PostSearch() {
  const { posts, postSearchInput, setPostSearchInput, postSearchResult, setPostSearchResult } = useData();

  const inputChangeHandler = (e) => {
    setPostSearchResult([]);
    setPostSearchInput(e.target.value);
    const inputValue = e.target.value.replaceAll(" ", "");
    if (!inputValue) return;
    let tempResult = []
    for (const [postId, post] of posts) {
      let targetText = `${post.content} ${post.world} ${post.ingame}`;
      if ("items" in post) {
        for (const item of post.items) {
          targetText += " " + itemData[item.id].name;
        }
      }
      let count = 0;
      let begin = undefined;
      const indexList = [];
      for (let i = 0; i < inputValue.length; i++) {
        if (inputValue[i] === " ") continue;
        const index = targetText.indexOf(inputValue[i], begin);
        if (index !== -1) {
          begin = index;
          indexList.push(index);
          count++;
        } else {
          break;
        }
      }
      if (count == inputValue.length) {
        let sum = indexList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        tempResult.push([sum, postId]);
        tempResult.sort((a, b) => a[0] - b[0]);
        setPostSearchResult(tempResult.map(innerArray => innerArray[1]));
      }
    }
  }

  return (
    <label className="input input-bordered flex items-center gap-2 w-72">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" /></svg>
      <input onChange={inputChangeHandler} type="text" className="grow" placeholder="Search" value={postSearchInput} />
    </label>
  );
}

export default PostSearch;