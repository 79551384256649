import Job from './Job';
import itemData from './item_data.json';

function ItemInfo({item, itemIndex}) {
  const optionUpDown = (option, defaultOptions) => {
    if (option[0] in defaultOptions) {
      
      const diff = option[1] - defaultOptions[option[0]].mid;
      if (diff < 0) return (
        <span className='ml-1 text-xs text-blue-300'>
          ↓{Math.abs(diff)}
        </span>
      )
      if (diff > 0) return (
        <span className='ml-1 text-xs text-red-300'>
          ↑{Math.abs(diff)}
        </span>
      )
    } else {
      return (
        <span className='ml-1 text-xs text-red-300'>
          ↑{option[1]}
        </span>
      )
    }
    return (<></>)
  }

  return (
    <div tabIndex={0} key={itemIndex + "items"} id={"item" + itemIndex} 
      className='dropdown-content shadow flex flex-col items-center h-fit w-72 bg-[#5E5C90]/90 text-slate-200 p-2 rounded-box mt-2 z-50'>
      <div className={'options' in item && "업그레이드 횟수" in item.options && item.options["업그레이드 횟수"] ? "text-[#e58c1a]" : ""}>
        <span className='text-xl font-bold mr-1'>{itemData[item.id].name}</span>
        {
          'options' in item && "업그레이드 횟수" in item.options && item.options["업그레이드 횟수"] ?
          <span className='text-xl font-bold mr-1'>(+{item.options["업그레이드 횟수"]})</span> : ""
        }
      </div>
      <div className='flex flex-row w-full mt-2'>
        {
          item.id[0] === "1" ?
          <div className='bg-slate-200 w-24 h-24 flex justify-center items-center mr-3 ml-3 mt-1 mb-1'>
            <img className='object-contain w-20 h-20' src={itemData[item.id].image} />
          </div>
          :
          <div className='bg-slate-200 w-16 h-16 flex justify-center items-center mr-3 ml-3 mt-1 mb-1'>
            <img className='object-contain w-12 h-12' src={itemData[item.id].image} />
          </div>
        }
        {
          item.id[0] === "1" ?
          <div className='text-xs ml-1 pl-1'>
            <div>
              <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ LEV :</span>
              <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_lev}</span>
            </div>
            <div>
              <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ STR :</span>
              <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_str}</span>
            </div>
            <div>
              <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ DEX :</span>
              <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_dex}</span>
            </div>
            <div>
              <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ INT :</span>
              <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_int}</span>
            </div>
            <div>
              <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ LUK :</span>
              <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_luk}</span>
            </div>
            <div>
              <span style={{ fontFamily: "intelone-mono-font-family-regular" }}>REQ POP :</span>
              <span className='ml-1' style={{ fontFamily: "intelone-mono-font-family-regular" }}>{itemData[item.id].req_pop}</span>
            </div>
          </div>
          :
          <div className='text-xs ml-1 pl-1 w-36'>
            {"desc" in itemData[item.id] ? itemData[item.id].desc : ""}
          </div>
        }
      </div>
      {
        "job" in itemData[item.id] ?
        <Job job={itemData[item.id].job} /> : ""
      }
      {
        item.id[0] === "1" ?
        <hr className='border-1 w-full mt-2 mb-2' /> : ""
      }
      {
        item.id[0] === "1" ?
        <div className='flex flex-col items-center'>
          <div>
            <span>장비분류 :</span>
            <span className='ml-1'>{itemData[item.id].category[2]}</span>
          </div>
          {
            "options" in itemData[item.id] && "공격속도" in itemData[item.id].options ?
            <div>
              <span>공격속도 :</span>
              <span className='ml-1'>{itemData[item.id].options["공격속도"]}</span>
            </div>
            : ""
          }
          {
            "options" in itemData[item.id] ?
            Object.entries(item.options).map((option, optionIndex) => {
              if (option[0] === "업그레이드 횟수" 
              || option[0] === "업그레이드 가능 횟수"
              || option[0] === "공격속도") {
                return
              }
              return <div key={optionIndex + "opt"}>
                <span>{option[0]} :</span>
                <span className='ml-1'>
                  +
                  {option[1]}
                </span>
                {optionUpDown(option, itemData[item.id].options)}
                
              </div>
            }) : ""
          }
          {
            "options" in itemData[item.id] ?
            <div>
              <span>업그레이드 가능 횟수 :</span>
              <span className='ml-1'>
                {item.options["업그레이드 가능 횟수"]}
              </span>
            </div> : ""
          }
        </div> : ""
      }
    </div>
  );
}

export default ItemInfo;