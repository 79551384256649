import { useEffect, useState } from "react";
import { useData } from "../context/Context";
import itemData from './item_data.json';
import ItemInfo from "./ItemInfo";

function Chat() {
  const { room, setRoom, posts, socket, chatList, setChatList, alertSoundOn, setAlertSoundOn } = useData();
  const [ message, setMessage ] = useState("");
  const [ sending, setSending ] = useState(false);

  useEffect(() => {
    setAlertSoundOn(true);
  }, [])

  const chatListComponents = (chatList) => {
    return [...chatList].reverse().map((chat, index) => {
      if (chat.sender === "server") {
        return (
          <div key={index} className="flex flex-row text-xs items-center justify-center text-gray-400 pb-2">
            <p>{chat.message}</p>
          </div>
        )
      }
      let cn = "flex flex-row items-start pb-1";
      if (chat.sender === chat.me) {
        cn += " text-cyan-600";
      }
      if (chat.sender === chat.post) {
        return (
          <div key={index} className={cn}>
            <p className="flex flex-row w-full break-all">
              [방장]{posts.get(room).world} : {chat.message}
            </p>
          </div>
        );
      } else {
        return (
          <div key={index} className={cn}>
            <p>손님{chat.sender.slice(0, 6)} : {chat.message}</p>
          </div>
        );
      }
    })
  }

  const enterChatEvent = () => {
    if (socket && message.trim() && !sending) {
      setSending(true);
      const data = {
        action: 'chat',
        data: {
          id: posts.get(room).id,
          message: message
        }
      };
      setMessage("");
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(data));
      } else {
        console.log('WebSocket is not open');
      }
      setSending(false);
    }
  }

  return (
    <div className="fixed right-5 bottom-2 z-50">
      {
        posts.has(room) ?
        <div className="card w-96 bg-white drop-shadow-2xl p-4 border border-slate-100">
          <div className="flex flex-row justify-between">
          <div>
            {
              posts.get(room).items.map((item, itemIndex) => 
              <div key={itemIndex + "item_image"} className="dropdown dropdown-hover dropdown-end">
                <div tabIndex={0} role="button" className="btn btn-sm btn-circle">
                  <img className='object-contain w-5 h-5' src={itemData[item.id].image} alt={itemData[item.id].name + "_item_image"} />
                </div>
                <ItemInfo item={item} itemIndex={itemIndex} />
              </div>
              )
            }
            </div>
            <button onClick={() => {
              const confirmText = "정말 나가시겠습니까? 방에서 나가면 방이 사라지거나 채팅 기록이 모두 지워집니다.";
              const result = window.confirm(confirmText);
              if (result) {
                if (socket) {
                  const data = {
                    action: 'leave', // API Gateway에서 설정한 라우팅 키 또는 액션
                    data: {
                      id: posts.get(room).id
                    }
                  };
                  if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify(data));
                    setRoom("");
                    setChatList([]);
                  } else {
                    console.log('WebSocket is not open');
                  }
                }
              }
            }} className="btn btn-circle btn-ghost btn-sm mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>
          </div>
          <div className="card">
            <pre><p className="break-all font-bold whitespace-break-spaces p-1">{posts.get(room).content}</p></pre>
          </div>
          <div className="flex flex-row justify-between items-end mt-4">
            <div className="flex flex-col">
              <span className="text-xs text-slate-500">월드 닉네임 : {posts.get(room).world}</span>
              <span className="text-xs text-slate-500 mt-1">인게임 닉네임 : {posts.get(room).ingame}</span>
            </div>
            <div className="flex flex-row items-center">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="10pt" height="10pt" viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M6220 11264 c-306 -38 -562 -121 -793 -257 -615 -363 -980 -1025 -954 -1732 15 -400 141 -764 377 -1085 80 -109 311 -338 417 -413 244 -173 510 -285 807 -339 173 -32 479 -32 652 0 297 54 563 166 807 339 106 75 337 304 417 413 179 244 297 518 352 820 18 94 22 154 22 330 0 244 -16 356 -78 562 -186 611 -672 1098 -1283 1284 -183 55 -320 76 -528 79 -99 2 -196 1 -215 -1z"/>
                <path d="M4775 6814 c-113 -15 -243 -40 -325 -64 -712 -207 -1218 -811 -1299 -1552 -7 -65 -11 -689 -11 -1883 l0 -1785 3260 0 3260 0 0 1785 c0 1185 -4 1818 -11 1883 -80 740 -600 1357 -1309 1555 -232 65 -102 61 -1900 63 -899 0 -1648 0 -1665 -2z"/>
                </g>
              </svg>
              <span className="text-xs ml-1">
                {posts.get(room).members.length} / {posts.get(room).max}
              </span>
            </div>
          </div>
          <div className="relative flex flex-col flex-col-reverse rounded-2xl w-full bg-base-100 mt-6 pt-2 pb-2 pl-5 pr-5 h-72 border border-slate-300 overflow-y-auto">
            {chatListComponents(chatList)}
            <div className="flex flex-row text-xs items-center justify-center text-gray-400 pb-4">
              <p>[안내] 채팅방에 입장하셨습니다. 동시에 하나의 채팅방에만 입장 가능하며, 새로고침을 하거나 브라우저를 닫으면 채팅방에서 나가집니다. 채팅방에서 나가면 채팅 기록을 다시 볼 수 없습니다.</p>
            </div>
          </div>
          
          <div className="w-full flex flex-row justify-start items-center">
            <label className="label cursor-pointer">
              <span className="label-text text-xs mr-1">채팅 알림음</span> 
              <input type="checkbox" value={alertSoundOn} defaultChecked onChange={(e) => { setAlertSoundOn(e.target.checked); }} className="checkbox checkbox-xs" />
            </label>
          </div>
          
          <label className="flex items-center gap-2 border border-slate-300 rounded-3xl">
            <input onKeyPress={(e) => {
              if (e.key === 'Enter') {
                enterChatEvent();
                e.preventDefault();
              }
            }} onChange={(e) => { setMessage(e.target.value); }} maxLength={100} value={message} type="text" className="input input-ghost w-full max-w-xs outline-none focus:outline-none border-none" />
            <button 
              onClick={enterChatEvent}
              className="btn btn-accent btn-sm mr-2 enter-button"
            >
              입력
            </button>
          </label>
        </div>
        :
        <></>
      }
    </div>
  );
}

export default Chat;
